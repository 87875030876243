<template>
	<div class="index">
		<div class="chat-list">
			<div class="nav-title">秘书台</div>
			<div class="chat-list-user">
				<div v-for="(item, index) in chatList" :key="index" @click="lookChatRecords(index, item)">
					<div class="user-info" :class="current == index ? 'active-background' : ''">
						<span>{{ item.name }}</span>
						<i class="el-icon-arrow-down" v-if="!item.isShow"></i>
						<i class="el-icon-arrow-up" v-if="item.isShow"></i>
					</div>

					<transition-group name="fade">
						<ul v-show="item.isShow" class="user-sub-list" :key="index">
							<li v-for="(subItem, subindex) in item.children" :key="subindex"
								@click.stop="selectGroup(subItem, index, subindex)" class="subItem-li"
								:class="groupId == subItem.id ? 'active-subItem-li' : ''">
								<span class="groupName-box">{{ subItem.groupName }} </span>
								<span class="red-dot" v-if="subItem.noRead"></span>
								<span class="sign-box" v-if="subItem.isAdmin == 0 && subItem.signInFlag == 0"
									@click.stop="signIn(subItem)">签到</span>
							</li>
						</ul>
					</transition-group>
				</div>
			</div>
		</div>
		<div v-if="isShow" class="default">
			<img src="@/assets/images/logo@3x.png" />
		</div>
		<spider-window-messagebox ref="messagebox" v-else />
	</div>
</template>

<script>
	import spiderWindowMessagebox from "../../../components/spider-window-message/spider-window-messagebox.vue";
	import {
		mapState
	} from "vuex";

	export default {
		components: {
			spiderWindowMessagebox
		},
		computed: {
			...mapState({
				RongIMClient: (state) => state.rongyun.RongIMClient, //融云实例
				updateGroupStatus: (state) => state.updateNum.updateGroupStatus, //刷新数组列表
				targetId: (state) => state.rongyun.targetId, //当前聊天的目标id
			}),
		},
		data() {
			return {
				current: null,
				isShow: true,
				chatList: [{
						id: 1,
						name: "会议",
						isShow: false,
						children: [],
					},
					{
						id: 2,
						name: "活动",
						isShow: false,
						children: [{
							subname: "子"
						}]
					},
					{
						id: 3,
						name: "婚礼",
						isShow: false,
						children: [{
							subname: "子"
						}]
					},
					{
						id: 4,
						name: "宴席",
						isShow: false,
						children: [{
							subname: "子"
						}]
					},
					{
						id: 5,
						name: "殡礼",
						isShow: false,
						children: [{
							subname: "子"
						}]
					},
					{
						id: 6,
						name: "庆典",
						isShow: false,
						children: [{
							subname: "子"
						}]
					},
					{
						id: 7,
						name: "生日会",
						isShow: false,
						children: [{
							subname: "子"
						}]
					},
					{
						id: 0,
						name: "群聊",
						isShow: false,
						children: [{
							subname: "子"
						}]
					},
				],
				groupId: null,
				watchListStatus: 0,
				unReadGroupNum: 0, //未读群组数量
			};
		},
		created() {
			this.setChatListChildren(); //获取每个群组相关
		},
		watch: {
			// watchListStatus(newVal, oldVal) {
			//   let arr = [];
			//   this.chatList.forEach((item, index) => {
			//     if (item.children.length > 0) {
			//       item.children.forEach((p) => {
			//         if (p.isFirst) {
			//           arr.push(index);
			//           this.$set(item, "isShow", true);
			//         }
			//       });
			//     }
			//   });
			//   if (arr.length > 0) {
			//     this.current = arr[0];
			//   }
			// },
			//监听刷新列表
			updateGroupStatus(newVal, oldVal) {
				oldVal = newVal
				//刷新群组列表
				this.setChatListChildren()
			},
			//监听融云实例
			RongIMClient(newVal, oldVal) {
				oldVal = newVal;
			},
			//目标id
			targetId(newVal, oldVal) {
				oldVal = newVal;
			}
		},
		methods: {
			// 查看对应用户聊天记录
			lookChatRecords(index, item) {
				// this.cleanClass(item);
				//如果有未读消息 则不允许折叠
				let isRed = false
				item.children.forEach(item => {
					if (item.noRead) {
						isRed = true
					}
				})
				if (isRed) {
					return
				}
				if (this.current != null && this.current != index) {
					this.chatList[this.current].isShow = false;
				}
				item.isShow = !item.isShow;
				this.current = index;
				// this.isShow = false;
			},
			cleanClass(item) {
				this.chatList.map((subitem) => {
					if (item.isShow == subitem.isShow) {
						return;
					}
					subitem.isShow = false;
				});
			},
			//选择子列表 展示群组成员
			selectGroup(item, index, subIndex) {
				// console.log(item,index,subIndex)
				this.clearRedDot(item, index, subIndex);
				this.groupId = item.id;
				item.from = "group";
				item.targetId = item.id;
				this.isShow = false; //展示聊天框
				//异步一下 防止获取不到ref
				setTimeout(() => {
					this.$refs.messagebox.init(item);
				}, 200);
			},
			//设置秘书台的子列表
			setChatListChildren() {
				let arr = [];
				this.chatList.forEach((item) => {
					//获取群组相关列表  类型 0默认群聊 1会议 2活动 3婚礼4宴席5殡礼6庆典7生日会
					this.$api.group.groupGroupInfoList(item.id).then((res) => {
						//设置子列表
						res.result.forEach((p) => {
							this.$set(p, "id", p.id.toString());
							this.$set(p, "memberId", p.memberId.toString());
							arr.push(item);
							this.watchListStatus = arr.length;
						});
						const list = (res.result || []).filter(item => item.signInFlag == 1);
						this.$set(item, "children", list.reverse());
					});
				});
				//计算红点 延时一下 这个要循环请求拿到子数据 没有一段时间不能获取到所有的子id 测试2秒才能拿完
				setTimeout(() => {
					this.computedRedDot();
				}, 2200)
				// this.$store.dispatch('changeGroupMsgNum',this)
			},
			//清除红点
			clearRedDot(val) {
				// 清除未读数
				let conversation = this.RongIMClient.Conversation.get({
					targetId: val.id,
					type: RongIMLib.CONVERSATION_TYPE.GROUP,
				});
				conversation.read().then(() => {
					console.log("清除指定会话未读数成功");
					this.computedRedDot()
					this.$set(val, 'noRead', false)
					// 发送同步消息
					conversation.send({
						messageType: "RC:SRSMsg",
						content: {
							//从消息里获取服务器端时间，以最近一条已读 message 为准
							// lastMessageSendTime: message.sentTime,
						},
					}).then((message) => {
						// console.log("发送同步消息成功", message);
					});
				});
			},
			//计算每个群组的红点
			computedRedDot() {
				this.unReadGroupNum = 0
				this.chatList.forEach((item) => {
					if (item.children.length > 0) {
						item.children.forEach((p, i) => {
							// console.log(p.id)
							//获取消息列表当前的未读数
							let conversation = this.RongIMClient.Conversation.get({
								targetId: p.id,
								type: RongIMLib.CONVERSATION_TYPE.GROUP,
							});
							conversation.getUnreadCount().then((count) => {
								// console.log("获取指定会话未读数成功", count);
								if (count > 0) {
									if (this.targetId != p.id) {
										this.$set(p, 'noRead', true)
										this.$forceUpdate()
									}
									this.$set(item, 'isShow', true)
									this.unReadGroupNum += 1
								} else {
									this.$set(p, 'noRead', false)
									this.$forceUpdate()
								}
							});
						});
					}
				});
				setTimeout(() => {
					console.log(this.unReadGroupNum)
					this.$store.commit('updateGroumNum', this.unReadGroupNum)
				}, 200)
				// arr = [...new Set(arr)]
				// let obj = {}
				// obj.num = arr.length
				// obj.path = '秘书台'
				// this.$store.commit("upDateNum",obj);
				// console.log("==============",arr)
			},
			//签到
			signIn(val) {
				// console.log(val)
				this.$api.member.signIn(val.id).then(res => {
					val.signInFlag = true
					this.$message({
						message: '签到成功',
						type: 'success'
					});
				})
			}
		},
	};
</script>

<style scoped>
	.index {
		display: flex;
		flex: 1;
	}

	.chat-list {
		width: 253px;
		user-select: none;
		cursor: pointer;
		height: 100%;
		background: #ffffff;
	}

	.chat-list .chat-list-user {
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		padding-bottom: 100px;
	}

	.nav-title {
		height: 60px;
		line-height: 60px;
		font-size: 18px;
		letter-spacing: 2px;
		padding: 10px;
		display: flex;
		align-items: center;
		background: #f5f7fa;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.chat-list-user .user-info {
		height: 38px;
		line-height: 38px;
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #7b7d80;
		/* padding: 0 12px; */
		border-bottom: 1px solid #dcdfe6;
		box-sizing: border-box;
		padding: 0 15px;
		justify-content: space-between;
		position: relative;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.2s;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}

	.user-sub-list {
		display: flex;
		height: auto;
		flex-direction: column;
	}

	.user-sub-list li {
		flex-shrink: 0;
		height: 40px;
		align-items: center;
		position: relative;
		font-size: 16px;
	}

	/* .user-sub-list li:hover {
      background: #ffffff;
    } */
	.user-info:hover {
		background: #f5f7fa;
	}

	.active-background {
		/* height: 38px; */
		line-height: 38px;
		font-size: 14px;
		color: #7b7d80;
		/* background: #f5f7fa; */
		/* padding: 0 12px; */
		border-bottom: 1px solid #dcdfe6;
		box-sizing: border-box;
	}

	.default {
		min-width: 506px;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-around;
		background: #f5f7fa;
		border-left: 1px solid #dcdfe6;
	}

	.default img {
		width: 100px;
		height: 100px;
	}

	.subItem-li {
		width: 100%;
		height: 57px;
		padding-left: 12px;
		font-size: 16px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		color: #313233;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #dcdfe6;
	}

	.user-info-title {
		width: calc(100% - 24px);
		padding: 0 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.subItem-li:hover {
		background: #f5f7fa;
	}

	.active-subItem-li {
		background: #f5f7fa;
	}

	.active-subItem-li:hover {
		background: #f5f7fa;
	}

	.groupName-box {
		max-width: 210px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.red-dot {
		width: 5px;
		height: 5px;
		background: #ff0000;
		border-radius: 50%;
		margin-left: 15px;
		flex-shrink: 0;
	}

	.sign-box {
		margin-left: 20px;
		font-size: 12px;
		color: #666;
	}
</style>